/*
 * @Author: lusj lusj9568@163.com
 * @Date: 2023-06-12 15:44:23
 * @LastEditors: lusj lusj9568@163.com
 * @LastEditTime: 2024-07-17 14:28:35
 * @FilePath: \line_web\src\App.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { BrowserRouter } from 'react-router-dom'
import WrapperRoutes from '@/router'
function App () {
  return (
    <div className="App">
      <BrowserRouter>
        <WrapperRoutes />
      </BrowserRouter>
    </div>
  )
}
export default App