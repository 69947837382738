/*
 * @Author: lusj lusj9568@163.com
 * @Date: 2024-07-12 17:28:14
 * @LastEditors: lusj lusj9568@163.com
 * @LastEditTime: 2024-07-17 14:28:52
 * @FilePath: \messages_web\src\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import App from './App'
import { createRoot } from 'react-dom/client'
import './index.scss'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(<App />)