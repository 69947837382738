/*
 * @Author: lusj lusj9568@163.com
 * @Date: 2023-06-12 17:16:22
 * @LastEditors: lusj lusj9568@163.com
 * @LastEditTime: 2024-08-15 10:40:06
 * @FilePath: \line_web\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { lazy } from 'react'
import { useRoutes, Navigate } from 'react-router-dom'

const Home = lazy(() => import('@/pages/Home'))
const Share = lazy(() => import('@/pages/Share'))
const PrivacyPolicy = lazy(() => import('@/pages/PrivacyPolicy'))
const NotFound = lazy(() => import('@/pages/NotFound'))

const routerList = [
  {
    path: '/',
    children: [
      {
        path: 'home',
        element: <Home />
      },
      // {
      //   path: 'share',
      //   element: <Share />
      // },
      {
        path: 'privacyPolicy',
        element: <PrivacyPolicy />
      },
      {
        path: '',
        element: <Navigate to="home" replace />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
]

function WrapperRoutes () {
  let element = useRoutes(routerList)
  return element
}

export default WrapperRoutes